<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-toilet</v-icon>
               <h2>¿Tiene un sistema de aguas grises instalado en su casa?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0" justify="center">
               <v-chip-group
                    v-model="aguasgrises"
                    column
                    :error-messages="aguasgrisesErrors"
                    class="text-center"
                    @change="setAguasGrises($event)"
                >
                    <v-chip
                    filter
                    outlined
                    value="si"
                    >
                        Si
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="no"
                    >
                        No
                    </v-chip>
               </v-chip-group>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q16"
                >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    @click="modalUser = true"
                    :disabled="!aguasgrises"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>

       <!-- Dialogo Dialogo show info result -->
    <v-dialog
      v-model="modalUser"
      width="400"
      scrollable
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Registro </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12" class="mb-0 pb-0">
               <v-text-field
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Nombre"
                    outlined
                    @input="setName($event)"
                    @blur="$v.name.$touch()"
                ></v-text-field>
                <v-text-field
                    v-model="cellphone"
                    :error-messages="cellphoneErrors"
                    label="Celular"
                    outlined
                    inputmode="numeric"
                    @input="setCellphone($event)"
                    @blur="$v.cellphone.$touch()"
                ></v-text-field>
                <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="Email"
                    outlined
                    @input="setEmail($event)"
                    @blur="$v.email.$touch()"
                ></v-text-field>
                
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalUser = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>

            <v-btn
                color="success"
                outlined
                to="/result"
                :disabled="!name || !cellphone || !email || !nameErrors.length == 0 || !cellphoneErrors.length == 0 || !emailErrors.length == 0"
            >
            <v-icon left>mdi-chart-areaspline</v-icon> Ver resultado
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo show info result -->

   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric, email } from 'vuelidate/lib/validators'
const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

export default {
    
    mixins: [validationMixin],

    validations: {
      aguasgrises: { required, numeric },
      name: { required },
      cellphone: { required, numeric, phoneValid: isPhone },
      email: { required, email }

    },

    data() {
        return {
            aguasgrises: '',
            modalUser: false,
            name: '',
            cellphone: '',
            email: ''
        }
    },

    mounted() {
        this.aguasgrises = this.$store.state.aguasgrises
        this.$store.state.progress = 95
        this.goBackIfEmpty()
    },

    computed: {
        aguasgrisesErrors () {
            const errors = []
            if (!this.$v.aguasgrises.$dirty) return errors
            !this.$v.aguasgrises.required && errors.push('Preferencias de aguasgrises es requerido.')
            !this.$v.aguasgrises.numeric && errors.push('Debe ser un número.')
            return errors
        },
        nameErrors () {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('el nombre es un campo requerido.')
            return errors
        },
        cellphoneErrors () {
            const errors = []
            if (!this.$v.cellphone.$dirty) return errors
            !this.$v.cellphone.required && errors.push('El celular es un campo requerido.')
            !this.$v.cellphone.numeric && errors.push('Debe ser un número.')
            !this.$v.cellphone.phoneValid && errors.push('El celular no es valido.')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('El email es un campo requerido.')
            !this.$v.email.email && errors.push('Debe ser un email válido.')
            return errors
        },
    },

    methods: {
        setAguasGrises(value) {
            this.$store.state.aguasgrises = value
            this.$v.aguasgrises.$touch()
        },
        setName(value) {
            this.$store.state.user.name = value
            this.$v.name.$touch()
        },
        setCellphone(value) {
            this.$store.state.user.cellphone = value
            this.$v.cellphone.$touch()
        },
        setEmail(value) {
            this.$store.state.user.email = value
            this.$v.email.$touch()
        },
       goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }else if(!this.$store.state.comida) {
               this.$router.push('/q5')
           }else if(!this.$store.state.dulces) {
               this.$router.push('/q6')
           }else if(!this.$store.state.verduras) {
               this.$router.push('/q7')
           }else if(!this.$store.state.frutas) {
               this.$router.push('/q8')
           }else if(!this.$store.state.tuberculos) {
               this.$router.push('/q9')
           }else if(!this.$store.state.cafe) {
               this.$router.push('/q10')
           }else if(!this.$store.state.tee) {
               this.$router.push('/q11')
           }else if(!this.$store.state.ducha) {
               this.$router.push('/q12')
           }else if(!this.$store.state.manos) {
               this.$router.push('/q13')
           }else if(!this.$store.state.inodoro) {
               this.$router.push('/q14')
           }else if(!this.$store.state.fregadero) {
               this.$router.push('/q15')
           }else if(!this.$store.state.lavadora) {
               this.$router.push('/q16')
           }else if(!this.$store.state.lavadoraTime) {
               this.$router.push('/q16')
           }
       }
    },
}
</script>

<style>
    .v-slide-group__content{
        justify-content: center;
    }
</style>